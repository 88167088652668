var loadNextPage = function(){
  if ($('.infinite-scroll').length) {
    console.log('aasas');
    if ($('#next_link').data("loading")){ return }  // prevent multiple loading
    var wBottom  = $(window).scrollTop() + $(window).height();
    var elBottom = $('.infinite-scroll').offset().top + $('.infinite-scroll').height();
    if (wBottom > elBottom && $('#next_link').length){
      console.log('a');
      $('#next_link')[0].click();
      $('#next_link').data("loading", true);
    } else {
      console.log('b');
      $('#div-next-link').hide();
    }
  }
};