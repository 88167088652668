(function ($) {

	"use strict";

	$(document).ready(function(){

		//-----------------------------------------------------------------------
		  // Fix for # href
		  //-----------------------------------------------------------------------
		  var aWithHref = document.querySelectorAll('a[href*="#"]');
		  aWithHref.forEach(function (el) {
		    el.addEventListener("click", function (e) {
		      e.preventDefault();
		    })
		  });

		// Lazy load
		// var lazyLoadInstance = new LazyLoad({
		//     elements_selector: ".lazy"
		// });

	
		// Sticky nav
		$(window).on('scroll', function () {
			if ($(this).scrollTop() > 1) {
				$('.element_to_stick').addClass("sticky");
			} else {
				$('.element_to_stick').removeClass("sticky");
			}
		});
		$(window).scroll();

		// Header background
		$('.background-image').each(function(){
			$(this).css('background-image', $(this).attr('data-background'));
		});

		// Rotate icons
		$(".categories_carousel .item a").hover(
			function(){$(this).find("img").toggleClass("rotate-x");}
		);
		
		// Menu
		$('a.open_close, a.toggleMenu').on("click", function () {
			$('.main-menu').toggleClass('show');
			$('.layer').toggleClass('layer-is-visible');
		});
		$('a.show-submenu').on("click", function () {
			$(this).next().toggleClass("show_normal");
		});
		
		// Opacity mask
		$('.opacity-mask').each(function(){
			$(this).css('background-color', $(this).attr('data-opacity-mask'));
		});

		// Scroll to top
		var pxShow = 800; // height on which the button will show
		var scrollSpeed = 500; // how slow / fast you want the button to scroll to top.
		$(window).scroll(function(){
		 if($(window).scrollTop() >= pxShow){
			$("#toTop").addClass('visible');
		 } else {
			$("#toTop").removeClass('visible');
		 }
		});
		$('#toTop').on('click', function(){
		 $('html, body').animate({scrollTop:0}, scrollSpeed);
		 return false;
		});	
		
		//Footer collapse
		var $headingFooter = $('footer h3');
		$(window).resize(function() {
	        if($(window).width() <= 768) {
	      		$headingFooter.attr("data-toggle","collapse");
	        } else {
	          $headingFooter.removeAttr("data-toggle","collapse");
	        }
	    }).resize();
		$headingFooter.on("click", function () {
			$(this).toggleClass('opened');
		});

		// Scroll to position
	    $('a[href^="#"].scrollToHash').on('click', function (e) {
				e.preventDefault();
				var target = this.hash;
				var $target = $(target);
				$('html, body').stop().animate({
					'scrollTop': $target.offset().top
				}, 800, 'swing', function () {
					window.location.hash = target;
				});
			});

	    // Header button explore
	    $('a[href^="#"].btn_explore').on('click', function (e) {
				e.preventDefault();
				var target = this.hash;
				var $target = $(target);
				$('html, body').stop().animate({
					'scrollTop': $target.offset().top
				}, 300, 'swing', function () {
					window.location.hash = target;
				});
			});

		// Like Icon
	    $('.btn_hero.wishlist').on('click', function(e){
	    	e.preventDefault();
			$(this).toggleClass('liked');
		});
		// Like Icon
	    $('.wish_bt').on('click', function(e){
	    	e.preventDefault();
			$(this).toggleClass('liked');
		});

		/* Tooltip */
		$('.tooltip-1').tooltip({
			html: true
		});

		// Popup up
	    setTimeout(function () {
	        $('.popup_wrapper').css({
	            "opacity": "1",
	            "visibility": "visible"
	        });
	        $('.popup_close').on("click", function () {
	            $(".popup_wrapper").fadeOut(300);
	        })
	    }, 1500);

	}); //$(document).on('turbolinks:load', function() {

})(window.jQuery); 