asyncLoad = function(url, containerId, justAppend) {
  $.ajax({
      type: "GET",
      url: url,
      success: function(data, textStatus, jqXHR) {
        var container = document.getElementById(containerId);
        if(justAppend) {
          container.insertAdjacentHTML('beforeend', data);
        } else {
          container.innerHTML = data;
        }
      },
      error: function(data) { 
        console.log("Erro na requisição assíncrona");
      }
    });
};

copyToClipboard = function(elementId) {
  var element = document.getElementById(elementId);
  var isHidden = false;
  if (element.style.visibility == "hidden") {
    isHidden = true;
  }

  element.style.visibility = "visible";
  element.select();
  element.setSelectionRange(0, 99999); /*For mobile devices*/
  element.blur();
  document.execCommand("copy");
  if (isHidden) {
    element.style.visibility = "hidden";
  }

  toastbox("toast-link-copiado", 2000);
}

disableButton = function(element) {
  element.disabled = true;
  element.innerText = element.getAttribute("data-disabled-text");
}

getDisplayMode = function() {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    return 'pwa';
  } else if (navigator.standalone || isStandalone) {
    return 'standalone';
  }
  return 'browser';
}

//-----------------------------------------------------------------------
// Get Geolocation
//-----------------------------------------------------------------------
getLocation = function(url_busca) {
  var geoOptions = {
    timeout: 10 * 1000 // 10 segs
  }

  var geoSuccess = function(position) {
    var location = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    }
    console.log("Localização: " + location);
    window.location.href = url_busca + "&lat=" + location.lat + "&lng=" + location.lng;
  };

  var geoError = function(error) {
    msg = ""
    switch(error.code) {
      case error.PERMISSION_DENIED:
          msg = "Usuário não deu permissão para acessar GeoLocation."
          break;
      case error.POSITION_UNAVAILABLE:
          msg = "Informação de localização indisponível."
          break;
      case error.TIMEOUT:
          msg = "Timeout - o usuário demorou para dar a permissão."
          break;
      case error.UNKNOWN_ERROR:
          msg = "Erro desconhecido."
          break;
    }
    console.log('Houve um erro. Motivo: ' + msg);
  };

  // Chamada do Geolocation
  if(navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions);
  } else {
    console.log("GeoLocation não suportado pelo browser");
  }
};


getGeolocationFromIp = function() {
  $.getJSON('https://json.geoiplookup.io/?callback=?', function(data) {
    console.log(JSON.stringify(data, null, 2));
  });
};


//-----------------------------------------------------------------------

goTo = function(url) {
  window.location.href = url;
}

scrollToElement = function(elementId) {
  element = document.getElementById(elementId);
  if(element != null) {
    element.scrollIntoView({block: "start", behavior: "smooth"});
  }
}

scrollToElementWithOffset = function(elementId, offset) {
  element = document.getElementById(elementId);
  if(element != null) {
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - offset;

    document.scrollingElement.scrollTop = offsetPosition;
  }
}

// loaders
showLoader = function() {
  $("#loader").fadeIn(300, "linear");
}
hideLoader = function() {
  $("#loader").fadeOut(300, "linear");
}

showLoaderAutoClose = function(ms) {
  $("#loader").fadeIn(300, "linear");
  sleep(ms).then(() => {
    hideLoader();
  });
}


selectAll = function(element) {
  element.select();
}

share = function(data) {
  var shareData = data;
  
  if(shareData == null) {
    shareData = {
      title: 'Personal Trainers de Confiança.',
      text: 'Encontre treinadores com as melhores avaliações no seu bairro',
      url: 'https://www.treinar.me'
    };
  }

  if (navigator.canShare(shareData)) {
    navigator.share(shareData)
      .then(() => console.log('Compartilhamento concluído.'))
      .catch((error) => console.log('Compartilhamento falhou', error));
  } else {
    console.log("O seu navegador não suporta a função de compartilhamento.");
    alert("O seu navegador não suporta a função de compartilhamento.");
  }
};


sleep = function(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}