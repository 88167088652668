

//-----------------------------------------------------------------------
// CUSTOM TREINAR.ME APP
//-----------------------------------------------------------------------
$(document).on('turbolinks:load', function() {

  // Vibrate on click
  $(".vibrate").click(function (e) {
    const canVibrate = navigator.vibrate;
    if (canVibrate) navigator.vibrate([5]);
  });

  $('[maxlength]').maxlength({
    alwaysShow: true,
    validate: true,
    separator: '/',
    placement: 'top-right-inside',
    showCharsTyped: true
  });

  $(".scrollTo").click(function (el) {
    el.preventDefault();
    var target = this.getAttribute("data-bs-target");
    var targetEl = document.querySelector(target);
    window.scrollTo({
      top: targetEl.offsetTop - document.body.scrollTop,
      behavior: 'smooth'
    });
  });

  $("form.disable-on-submit").submit(function (el) {
    var buttons = document.querySelectorAll(".btn");
    buttons.forEach(function (el) {
      var text = el.getAttribute("data-bs-disabled-text");
      if(text != null) {
        el.disabled = true;
        el.innerText = text;
        el.value = text;
      }
    });
  });

  $("#request-push-permission-cta").click(async function (el) {
    let permission = await Notification.requestPermission();

    if (permission === "granted") {
      console.info('O usuário concedeu permissão para receber notificações.');
    } else {
      // TODO: exibir um modal ensinando a desbloquear
      console.error('O usuário negou permissão para receber notificações.');
    }
  });

  
  //-----------------------------------------------------------------------



  //-----------------------------------------------------------------------
  // Install PWA prompt
  //-----------------------------------------------------------------------

  let deferredPrompt;
  const installPwaTrigger = document.querySelector('#install-pwa-trigger');

  window.addEventListener('beforeinstallprompt', (e) => {
    
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    
    if(installPwaTrigger == null) {
    

    } else {
      installPwaTrigger.style.display = 'block';

      installPwaTrigger.addEventListener('click', () => {
        deferredPrompt.prompt();

        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('Usuário aceitou instalação');
          } else {
            console.log('Usuário NÃO aceitou instalação');
          }
          deferredPrompt = null;
        });
      });
    }
  });

  window.addEventListener('appinstalled', () => {
    // Clear the deferredPrompt so it can be garbage collected
    deferredPrompt = null;
    // Optionally, send analytics event to indicate successful install
    console.log('PWA instalado com sucesso');
  });



  //-----------------------------------------------------------------------
  // Fix for # href
  //-----------------------------------------------------------------------
  var aWithHref = document.querySelectorAll('a[href*="#"]');
  aWithHref.forEach(function (el) {
    el.addEventListener("click", function (e) {
      e.preventDefault();
    })
  });
  //-----------------------------------------------------------------------


  //-----------------------------------------------------------------------
  // Go Top Button
  //-----------------------------------------------------------------------
  var goTopButton = document.querySelectorAll(".goTop");
  goTopButton.forEach(function (el) {
      // show fixed button after some scrolling
      window.addEventListener("scroll", function () {
        var scrolled = window.scrollY;
        if (scrolled > 100) {
          el.classList.add("show")
        }
        else {
          el.classList.remove("show")
        }
      })
      // go top on click
      el.addEventListener("click", function (e) {
        e.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      })

    })
  //-----------------------------------------------------------------------


  //-----------------------------------------------------------------------
  // Go Back Button
  var goBackButton = document.querySelectorAll(".goBack");
  goBackButton.forEach(function (el) {
    el.addEventListener("click", function () {
      window.history.go(-1);
    })
  })
  //-----------------------------------------------------------------------



  //-----------------------------------------------------------------------
  // OS Detection
  var osDetection = navigator.userAgent || navigator.vendor || window.opera;
  var windowsPhoneDetection = /windows phone/i.test(osDetection);
  var androidDetection = /android/i.test(osDetection);
  var iosDetection = /iPad|iPhone|iPod/.test(osDetection) && !window.MSStream;

  var detectionWindowsPhone = document.querySelectorAll(".windowsphone-detection");
  var detectionAndroid = document.querySelectorAll(".android-detection");
  var detectioniOS = document.querySelectorAll(".ios-detection");
  var detectionNone = document.querySelectorAll(".non-mobile-detection");

  if (windowsPhoneDetection) {
      // Windows Phone Detected
      detectionWindowsPhone.forEach(function (el) {
        el.classList.add("is-active");
      })
    }
    else if (androidDetection) {
      // Android Detected
      detectionAndroid.forEach(function (el) {
        el.classList.add("is-active");
      })
    }
    else if (iosDetection) {
      // iOS Detected
      detectioniOS.forEach(function (el) {
        el.classList.add("is-active");
      })
    }
    else {
      // Non-Mobile Detected
      detectionNone.forEach(function (el) {
        el.classList.add("is-active");
      })

    }
  //-----------------------------------------------------------------------


  //-----------------------------------------------------------------------
  // Tooltip
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
  //-----------------------------------------------------------------------


  //-----------------------------------------------------------------------
  // Input
  // Clear input
  var clearInput = document.querySelectorAll(".clear-input");
  clearInput.forEach(function (el) {
    el.addEventListener("click", function () {
      var parent = this.parentElement
      var input = parent.querySelector(".form-control")
      input.focus();
      input.value = "";
      parent.classList.remove("not-empty");
    })
  })
  // active
  var formControl = document.querySelectorAll(".form-group .form-control");
  formControl.forEach(function (el) {
      // active
      el.addEventListener("focus", () => {
        var parent = el.parentElement
        parent.classList.add("active")
        if (el.value.length > 0) {
          parent.classList.add("not-empty")
        }
      });
      el.addEventListener("blur", () => {
        var parent = el.parentElement
        parent.classList.remove("active")
      });
      // empty check
      el.addEventListener("keyup", log);
      function log(e) {
        var inputCheck = this.value.length;
        if (inputCheck > 0) {
          this.parentElement.classList.add("not-empty")
        }
        else {
          this.parentElement.classList.remove("not-empty")
        }
      }
    })
  //-----------------------------------------------------------------------


  //-----------------------------------------------------------------------
  // Searchbox Toggle
  var searchboxToggle = document.querySelectorAll(".toggle-searchbox")
  searchboxToggle.forEach(function (el) {
    el.addEventListener("click", function () {
      var search = document.getElementById("search")
      var a = search.classList.contains("show")
      if (a) {
        search.classList.remove("show")
      }
      else {
        search.classList.add("show")
        search.querySelector(".form-control").focus();
      }
    })
  });
  //-----------------------------------------------------------------------


  //-----------------------------------------------------------------------
  // Stepper
  var stepperUp = document.querySelectorAll(".stepper-up");
  stepperUp.forEach(function (el) {
    el.addEventListener("click", function () {
      var input = el.parentElement.querySelector(".form-control");
      var step = el.parentElement.getAttribute("data-bs-step");
      input.value = parseInt(input.value) + parseInt(step)
    })
  })
  var stepperDown = document.querySelectorAll(".stepper-down");
  stepperDown.forEach(function (el) {
    el.addEventListener("click", function () {
      var input = el.parentElement.querySelector(".form-control");
      var step = el.parentElement.getAttribute("data-bs-step");
      if (parseInt(input.value) > 0) {
        input.value = parseInt(input.value) - parseInt(step)
      }
    })
  })
  //-----------------------------------------------------------------------


  //-----------------------------------------------------------------------
  // Carousel
  // Splide Carousel
  document.addEventListener('DOMContentLoaded', function () {

    // Full Carousel
    document.querySelectorAll('.carousel-full').forEach(carousel => new Splide(carousel, {
      perPage: 1,
      rewind: true,
      type: "loop",
      gap: 0,
      arrows: false,
      pagination: false,
    }).mount());

    // Single Carousel
    document.querySelectorAll('.carousel-single').forEach(carousel => new Splide(carousel, {
      perPage: 3,
      rewind: true,
      type: "loop",
      gap: 16,
      padding: 16,
      arrows: false,
      pagination: false,
      breakpoints: {
        768: {
          perPage: 1
        },
        991: {
          perPage: 2
        }
      }
    }).mount());

    // Multiple Carousel
    document.querySelectorAll('.carousel-multiple').forEach(carousel => new Splide(carousel, {
      perPage: 4,
      rewind: true,
      type: "loop",
      gap: 16,
      padding: 16,
      arrows: false,
      pagination: false,
      breakpoints: {
        768: {
          perPage: 2
        },
        991: {
          perPage: 3
        }
      }
    }).mount());

    // Small Carousel
    document.querySelectorAll('.carousel-small').forEach(carousel => new Splide(carousel, {
      perPage: 9,
      rewind: false,
      type: "loop",
      gap: 16,
      padding: 16,
      arrows: false,
      pagination: false,
      breakpoints: {
        768: {
          perPage: 5
        },
        991: {
          perPage: 7
        }
      }
    }).mount());

    // Slider Carousel
    document.querySelectorAll('.carousel-slider').forEach(carousel => new Splide(carousel, {
      perPage: 1,
      rewind: false,
      type: "loop",
      gap: 16,
      padding: 16,
      arrows: false,
      pagination: true
    }).mount());

    // Stories Carousel
    document.querySelectorAll('.story-block').forEach(carousel => new Splide(carousel, {
      perPage: 16,
      rewind: false,
      type: "slide",
      gap: 16,
      padding: 16,
      arrows: false,
      pagination: false,
      breakpoints: {
        500: {
          perPage: 4
        },
        768: {
          perPage: 7
        },
        1200: {
          perPage: 11
        }
      }
    }).mount());
  });
  //-----------------------------------------------------------------------


  //-----------------------------------------------------------------------
  // Notification
  // trigger notification
  var notificationCloseButton = document.querySelectorAll(".notification-box .close-button");
  var notificationTaptoClose = document.querySelectorAll(".tap-to-close .notification-dialog");
  var notificationBox = document.querySelectorAll(".notification-box");

  function closeNotificationBox() {
    notificationBox.forEach(function (el) {
      el.classList.remove("show")
    })
  }
  function notification(target, time) {
    var a = document.getElementById(target);
    closeNotificationBox()
    setTimeout(() => {
      a.classList.add("show")
    }, 250);
    if (time) {
      time = time + 250;
      setTimeout(() => {
        closeNotificationBox()
      }, time);
    }
  }
  // close notification
  notificationCloseButton.forEach(function (el) {
    el.addEventListener("click", function (e) {
      e.preventDefault();
      closeNotificationBox();
    })
  });

  // tap to close notification
  notificationTaptoClose.forEach(function (el) {
    el.addEventListener("click", function (e) {
      closeNotificationBox();
    })
  });

  window.closeNotificationBox = closeNotificationBox;
  window.notification = notification;
  //-----------------------------------------------------------------------


  // -----------------------------------------------------------------------
  // Toast
  // trigger toast
  var toastCloseButton = document.querySelectorAll(".toast-box .close-button");
  var toastTaptoClose = document.querySelectorAll(".toast-box.tap-to-close");
  var toastBoxes = document.querySelectorAll(".toast-box");

  function closeToastBox() {
    toastBoxes.forEach(function (el) {
      el.classList.remove("show")
    })
  }
  function toastbox(target, time) {
    var a = document.getElementById(target);
    if(a == null) {return;}

    closeToastBox()
    setTimeout(() => {
      a.classList.add("show")
    }, 100);
    if (time) {
      time = time + 100;
      setTimeout(() => {
        closeToastBox()
      }, time);
    }
  }
  // close button toast
  toastCloseButton.forEach(function (el) {
    el.addEventListener("click", function (e) {
      e.preventDefault();
      closeToastBox();
    })
  })
  // tap to close toast
  toastTaptoClose.forEach(function (el) {
    el.addEventListener("click", function (e) {
      closeToastBox();
    })
  })

  window.toastbox = toastbox;
  window.closeToastBox = closeToastBox;
  //-----------------------------------------------------------------------


  //-----------------------------------------------------------------------
  // Header Scrolled
  // Animated header style
    var appHeader = document.querySelector(".appHeader.scrolled");
    function animatedScroll() {
      var scrolled = window.scrollY;
      if (scrolled > 20) {
        appHeader.classList.add("is-active")
      }
      else {
        appHeader.classList.remove("is-active")
      }
    }
    if (document.body.contains(appHeader)) {
      animatedScroll();
      window.addEventListener("scroll", function () {
        animatedScroll();
      })
    }
  //-----------------------------------------------------------------------

  //-----------------------------------------------------------------------
  // Offline Mode / Online Mode Detection

  // You can change the text here
  var OnlineText = "Conectado à internet";
  var OfflineText = "Sem conexão de internet";

  // Online Mode Toast Append
  function onlineModeToast() {
    var check = document.getElementById("online-toast");
    if (document.body.contains(check)) {
      check.classList.add("show")
    }
    else {
      pageBody.appendChild(document.createElement("div")).id = "online-toast";
      var toast = document.getElementById("online-toast");
      if(toast != null) {
        toast.className = "toast-box bg-success toast-top tap-to-close";
        toast.innerHTML =
        "<div class='in'><div class='text'>"
        +
        OnlineText
        +
        "</div></div>"
        setTimeout(() => {
          toastbox('online-toast', 3000);
        }, 500);
      }
    }
  }

  // Offline Mode Toast Append
  function offlineModeToast() {
    var check = document.getElementById("offline-toast");
    if (document.body.contains(check)) {
      check.classList.add("show")
    }
    else {
      pageBody.appendChild(document.createElement("div")).id = "offline-toast";
      var toast = document.getElementById("offline-toast");
      if (toast != null) {
        toast.className = "toast-box bg-danger toast-top tap-to-close";
        toast.innerHTML =
        "<div class='in'><div class='text'>"
        +
        OfflineText
        +
        "</div></div>"
        setTimeout(() => {
          toastbox('offline-toast', 3000);
        }, 500);
      }
    }
  }

  // Online Mode Function
  function onlineMode() {
    var check = document.getElementById("offline-toast");
    if (document.body.contains(check)) {
      check.classList.remove("show")
    }
    onlineModeToast();
    var toast = document.getElementById("online-toast")
    if (toast != null) {
      toast.addEventListener("click", function () {
        this.classList.remove("show")
      })
      setTimeout(() => {
        toast.classList.remove("show")
      }, 4000);
    }
  }

  // Online Mode Function
  function offlineMode() {
    var check = document.getElementById("online-toast");
    if (document.body.contains(check)) {
      check.classList.remove("show")
    }
    offlineModeToast();
    var toast = document.getElementById("offline-toast")
    if (toast != null) {
      toast.addEventListener("click", function () {
        this.classList.remove("show")
      })
      setTimeout(() => {
        toast.classList.remove("show")
      }, 4000);
    }
  }

  // Check with event listener if online or offline
  window.addEventListener('online', onlineMode);
  window.addEventListener('offline', offlineMode);
  //-----------------------------------------------------------------------


  //-----------------------------------------------------------------------
  // Upload Input
  var uploadComponent = document.querySelectorAll('.custom-file-upload');
  uploadComponent.forEach(function (el) {
    var fileUploadParent = '#' + el.id;
    var fileInput = document.querySelector(fileUploadParent + ' input[type="file"]')
    var fileLabel = document.querySelector(fileUploadParent + ' label')
    var fileLabelText = document.querySelector(fileUploadParent + ' label span')
    var filelabelDefault = fileLabelText.innerHTML;
    fileInput.addEventListener('change', function (event) {
      var name = this.value.split('\\').pop()
      tmppath = URL.createObjectURL(event.target.files[0]);
      if (name) {
        fileLabel.classList.add('file-uploaded');
        fileLabel.style.backgroundImage = "url(" + tmppath + ")";
        fileLabelText.innerHTML = name;
      }
      else {
        fileLabel.classList.remove("file-uploaded")
        fileLabelText.innerHTML = filelabelDefault;
      }
    })
  })
  //-----------------------------------------------------------------------


  //-----------------------------------------------------------------------
  // Multi-level Listview
  var multiListview = document.querySelectorAll(".listview .multi-level > a.item");

  multiListview.forEach(function (el) {
    el.addEventListener("click", function () {
      var parent = this.parentNode;
      var listview = parent.parentNode;
      var container = parent.querySelectorAll('.listview')
      var activated = listview.querySelectorAll('.multi-level.active');
      var activatedContainer = listview.querySelectorAll('.multi-level.active .listview')

      function openContainer() {
        container.forEach(function (e) {
          e.style.height = 'auto';
          var currentheight = e.clientHeight + 10 + 'px';
          e.style.height = '0px'
          setTimeout(() => {
            e.style.height = currentheight
          }, 0);
        })
      }
      function closeContainer() {
        container.forEach(function (e) {
          e.style.height = '0px';
        })
      }
      if (parent.classList.contains('active')) {
        parent.classList.remove('active');
        closeContainer();
      }
      else {
        parent.classList.add('active');
        openContainer();
      }
      activated.forEach(function (element) {
        element.classList.remove('active');
        activatedContainer.forEach(function (e) {
          e.style.height = '0px'
        })
      })
    });
  })
  //-----------------------------------------------------------------------



});