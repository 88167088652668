require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


require("jquery")
require("easy-autocomplete")
require("bootstrap-maxlength")

require("./custom/pagy")
require("./custom/bootstrap.min")
require("./custom/util")
require("./custom/base")

require("./site/sticky_sidebar.min")
require("./site/common_scripts")
require("./site/common_func")

var Inputmask = require("inputmask");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../../assets/images', true, /\.(gif|jpg|png|svg|ico)$/i)
const imagePath = (name) => images(name, true)
